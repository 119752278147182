@import 'prismjs/themes/prism-coy.css';

.topbar {
    .topbar-left {
        div {
            margin-left: 10px;
            vertical-align: top;
            font-size: 1.75rem;
            font-weight: lighter;
            color: #fff;
        }
        > button > * {
            display: inline-block;
        }
    }
    .topbar-right.acaa {
        padding: 0 15px;

        #menu-button.p-link {
            margin-top: 15px;
        }
        .user-menu {
            position: absolute;
            right: 15px;
            
            .menu-toggle {
                position: relative;
                margin: 2px;
                padding: 4px 6px;
                border: 2px solid rgba(246, 246, 246, 0.8);
                background-color: rgba(0, 0, 0, 0);

                > div {
                    display: flex;
                    flex-direction: column;
                }
                .name {
                    display: inline-block;
                }
                img {
                    max-height: 40px;
                    margin-left: 8px;
                }
            }
        }
    }
}

.date-selectors {
    width: 100%;

    > div {
        display: inline-block;
        width: calc(50% - 50px);
        margin-bottom: 16px;
        vertical-align: top;

        span {
            width: 98%;
        }
    }
    button {
        vertical-align: top;
        margin-top: 18px;
    }
    i {
        vertical-align: top;
        margin-left: 8px;
        margin-top: 22px;
        font-size: 2rem;
        cursor: pointer;
    }
}

.summary-chart {
    display: inline-block;
    width: calc(33% - 8px);
    max-width: 33%;
    margin: 0px 4px;
    padding: 8px;
    background-color: #FFF;
    border-radius: 4px;

    .counts {
        width: 100%;
        padding: 0px 24px;
        margin-bottom: 16px;
        text-align: center;
        border: 2px solid #DEE2E6;

        > div {
            display: inline-block;
            padding: 12px;
            font-size: 1.4rem;

            p {
                font-weight: bold;
            }
        }
        .total {
            width: 100%;
            margin-bottom: 12px;
            border-bottom: 2px solid #DEE2E6;
            
            p {
                font-size: 1.9rem;
            }
        }
        .depart, .arrive {
            width: 50%;
            margin-bottom: 12px;
        }
        .arrive {
            border-left: 2px solid #DEE2E6;
        }
    }
}

.table-section {
    display: inline-block;
    width: calc(25% - 8px);
    max-width: 25%;
    margin: 0 4px;
    vertical-align: top;
    background-color: #E7E7E7;
    border-radius: 4px;

    h1 {
        margin-top: 4px;
        margin-left: 8px;
    }
}

i.boolean {
    font-size: 2rem;
}
i.boolean.pi.pi-circle {
    color: #080;
}
i.boolean.pi.pi-times {
    color: #B23B3B;
}

#Dashboard {
    img {
        width: 60%;
        max-height: none;
    }
    > p {
        text-align: center;
        margin-top: 8px;
    }
}

#FlightDetail, #FlightCount, #GatePax, #RoutePax, #InvalidFlights {
    .p-datatable {
        padding: 16px;
        background-color: #FFF;
        border-radius: 6px;

        .p-datatable-wrapper {
            border: 1px solid #DEE2E6;
        }
    }
}

#GatePax, #FlightCount {
    tfoot td {
        font-weight: bold;
        background-color: #F3F3F3;
    }
    .export-footer {
        width: calc(100% - 24px);
        margin: 12px;
        padding: 0.5rem;
        background: #FFF;
    }
}

#FlightDetail, #RoutePax {
    .load-factor {
        padding: 2px 4px;

        &.low {
            background-color: #FF9F9F;
        }
    }
}

#FlightDetail, #RoutePax, #InvalidFlights {
    .date-selectors .p-progress-spinner {
        width: 56px;
        height: 48px;
    }
}

#FlightCount {
    .table-section {
        &.big {
            width: calc(32% - 8px);
            max-width: 32%;
        }

        &.mini {
            width: calc(17% - 8px);
            max-width: 17%;
        }
    }
}

#FlightDetail, #RoutePax .p-datatable-row-expansion {
    .load-factor {
        padding: 2px 4px;

        &.low {
            background-color: #FF9F9F;
        }
    }
}

#InvalidFlights {
    .date-selectors i.outage-target {
        margin: 0 4px 0 16px;
    }
    .outage-warning {
        width: auto;
    }
    .outage-target, .outage-warning {
        padding: 4px;
        border-radius: 4px;
        color: #EFD52A;
        background-color: #777;
        font-weight: bold;
    }
    td {
        &.big {
            font-size: 2rem;
            font-weight: bold;
        }
        .p-progress-spinner {
            width: 20px;
            height: 20px;
        }
    }
    .outer-expand {
        > div {
            display: inline-block;
            width: 20%;
            margin-left: 10%;
            vertical-align: top;

            span {
                display: inline-block;
                width: 90px;
                font-weight: bold;
            }
            div {
                margin-bottom: 4px;
            }
        }
    }
}

#AdvancedCharts {
    .data-selectors {
        > * {
            display: inline-block;
        }
        > button {
            height: 60px;
            margin-right: 8px;
            margin-top: 32px;
            vertical-align: top;
            background-color: #B23B3B;
            border: 1px solid #555;
            font-weight: bold;
        }
        .fields {
            width: calc(100% - 250px);

            > div {
                display: inline-block;
                width: 50%;
                margin-bottom: 20px;

                > span.p-calendar, .p-dropdown {
                    width: 98%;
                }
            }
        }
        .fetch-btns {
            width: 110px;
            vertical-align: top;
            
            p {
                margin-bottom: 4px;
            }
            button {
                display: block;
                margin: 4px auto;
                font-weight: bold;

                &.p-disabled {
                    background-color: #999;
                    color: #222;
                }
            }
        }
        > i {
            vertical-align: top;
            margin-left: 8px;
            margin-top: 22px;
            font-size: 2rem;
            cursor: pointer;
        }
    }
    .chart {
        padding: 24px;
        background-color: #FFF;
        border-radius: 6px;
    }
}
